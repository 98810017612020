<template>
  <div class="page-background">
    <div class="header-container">
      <div class="header-image-back-1"></div>
      <div class="header-image-back-2"></div>
      <div class="header-text">
        <div class="font-header">Automatiza & Digitaliza</div>
        <div class="font-description mt-2">
          Los procesos de diseño y rediseño curricular, sistematizando sus
          componentes para la evaluación y seguimiento de los resultados de
          aprendizaje de las asignaturas y las competencias del perfil de
          egreso.
        </div>
        <div class="button-container">
          <b-button
            @click="scrollToDiv('contact-us')"
            variant="none"
            class="user-button mt-4 p-0"
            >Contáctanos
          </b-button>
        </div>
      </div>
    </div>
    <div class="header-banner-container">
      <div class="header-banner-card header-banner-left mb-4">
        <div class="font-header">Automatiza & Digitaliza</div>
        <div class="font-description mt-2">
          Los procesos de diseño y rediseño curricular, sistematizando sus
          componentes para la evaluación y seguimiento de los resultados de
          aprendizaje de las asignaturas y las competencias del perfil de
          egreso.
        </div>
        <div>
          <b-button
            @click="scrollToDiv('contact-us')"
            variant="none"
            class="user-button mt-4 p-0"
            >Contáctanos
          </b-button>
        </div>
      </div>
      <div class="header-banner-card header-banner-right">
        <div class="header-banner-img-right"></div>
      </div>
    </div>
    <div
      class="mt-5"
      style="display: flex; flex-direction: column; flex-wrap: nowrap"
    >
      <div
        style="
          font-family: 'Source Sans 3';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 150%;
          /* or 60px */

          text-align: center;
          letter-spacing: -0.022em;

          color: #212121;
        "
      >
        SOLUCIÓN KEY LEARNING:
      </div>
      <div
        style="
          font-family: 'Source Sans 3';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 150%;
          /* or 60px */

          text-align: center;
          letter-spacing: -0.022em;

          color: #212121;
        "
      >
        KL INNOVATION – KL ASSESSMENT
      </div>
      <div
        style="
          font-family: 'Source Sans 3';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 100%;
          text-align: center;
          text-transform: uppercase;
          background: linear-gradient(90deg, #006ec8 24.78%, #01adb7 74.78%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        "
      >
        Proceso formativo
      </div>
      <div class="brochure-img mb-2">
        <div class="brochure-img-center"></div>
      </div>
    </div>
    <Brochure></Brochure>
    <b-carousel
      id="carousel-1"
      v-model="slide1"
      :interval="7000"
      controls
      indicators
      class="mb-5"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart1"
      @sliding-end="onSlideEnd1"
    >
      <div class="carousel-item">
        <img
          class="carousel1-img"
          src="../../assets/new-page/carousel-1-img-1.jpg"
          alt="..."
        />
        <div
          class="carousel-caption d-md-block"
          style="position: absolute; top: 25%"
        >
          <img
            class="mb-3"
            src="../../assets/new-page/carousel-1-logo-1.svg"
            alt="soluciones-img-1"
          />
          <div class="carousel-1-title" style="text-align: center">
            Es una Solución
            <span style="color: #01adb7">
              para construir y gestionar los currículos,</span
            >
          </div>
          <div class="carousel-1-description" style="text-align: center">
            ya sea desde la creación, ajuste, innovación y/o modificación de los
            planes de estudio, de manera integrada y automatizada, hasta el
            proceso de assessement de calificación y cualificación de los logros
            de resultados de aprendizajes, en un contexto compartido por sus
            diseñadores.
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="carousel1-img"
          src="../../assets/new-page/carousel-1-img-2.jpg"
          alt="..."
        />
        <div
          class="carousel-caption d-md-block"
          style="position: absolute; top: 25%"
        >
          <img
            class="mb-3"
            src="../../assets/new-page/carousel-1-logo-1.svg"
            alt="soluciones-img-1"
          />
          <div class="carousel-1-title" style="text-align: center">
            Solución<span style="color: #01adb7"> abierta </span>
          </div>
          <div class="carousel-1-description" style="text-align: center">
            al uso de los diferentes estamentos del proceso formativo,
            directivos académicos y de unidades, docentes y estudiantes.
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="carousel1-img"
          src="../../assets/new-page/carousel-1-img-3.jpg"
          alt="..."
        />
        <div
          class="carousel-caption d-md-block"
          style="position: absolute; top: 25%"
        >
          <img
            class="mb-3"
            src="../../assets/new-page/carousel-1-logo-1.svg"
            alt="soluciones-img-1"
          />
          <div class="carousel-1-title" style="text-align: center">
            Se constituye en un
            <span style="color: #01adb7">
              Sistema de Aseguramiento de la Calidad;
            </span>
          </div>
          <div class="carousel-1-description" style="text-align: center">
            con acceso a los registros sistemáticos de la información curricular
            y de resultados de aprendizaje de estudiantes para los procesos de
            acreditación de la Institución.
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="carousel1-img"
          src="../../assets/new-page/carousel-1-img-4.jpg"
          alt="..."
        />
        <div
          class="carousel-caption d-md-block"
          style="position: absolute; top: 25%"
        >
          <img
            class="mb-3"
            src="../../assets/new-page/carousel-1-logo-1.svg"
            alt="soluciones-img-1"
          />
          <div class="carousel-1-title" style="text-align: center">
            Es una solución SaaS (Software as a Service) y Cloud, que se ofrece
            como
            <span style="color: #01adb7">
              un servicio seguro en la nube de AWS
            </span>
          </div>
          <div class="carousel-1-description" style="text-align: center">
            (Amazon Web Services), lo que incluye mantención y soporte
            permanente a cada Institución que usa la plataforma. Con ello, la
            institución no requiere destinar recursos a hardware o software que
            deba soportar y mantener.
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="carousel1-img"
          src="../../assets/new-page/carousel-1-img-5.jpg"
          alt="..."
        />
        <div
          class="carousel-caption d-md-block"
          style="position: absolute; top: 25%"
        >
          <img
            class="mb-3"
            src="../../assets/new-page/carousel-1-logo-1.svg"
            alt="soluciones-img-1"
          />
          <div class="carousel-1-title" style="text-align: center">
            La Solución Nativa KL es
            <span style="color: #01adb7"> Costumizable </span>
          </div>
          <div class="carousel-1-description" style="text-align: center">
            a las características del modelo educativo y de las orientaciones
            curriculares de las Instituciones (IES).
          </div>
        </div>
      </div>
    </b-carousel>
    <b-carousel
      id="carousel-2"
      fade
      v-model="slide2"
      indicators
      :interval="7000"
      controls
      class="mb-5 carousel-2-style"
      background="#FFFFFF"
      @sliding-start="onSlideStart2"
      @sliding-end="onSlideEnd2"
    >
      <div id="carousel-2" class="carousel slide" data-ride="carousel-2">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="carousel-2-container">
              <div class="carousel-2-sub-container-left">
                <strong>Funcionalidades</strong> (integradas y automatizadas)
              </div>
              <div class="carousel-2-sub-container-right">
                <div
                  class="carousel-2-card carousel-2-background-1 carousel-2-space"
                >
                  <div class="align-self-left mt-3 ml-4">
                    <img
                      src="../../assets/new-page/Carousel-funcionalidades-icono-01.svg"
                      alt="funcionalidades-icono-01"
                    />
                  </div>
                  <div class="ml-4 mr-4 mt-3">
                    Construye los <strong>Perfiles de Egreso</strong> en base a
                    Capacidades, Competencias o RA. Currículo basado en
                    resultados de aprendizajes.
                  </div>
                </div>
                <div class="carousel-2-card carousel-2-background-2">
                  <div class="align-self-left mt-3 ml-4">
                    <img
                      src="../../assets/new-page/Carousel-funcionalidades-icono-02.svg"
                      alt="funcionalidades-icono-01"
                    />
                  </div>
                  <div class="ml-4 mr-4 mt-3">
                    <strong
                      >Genera matrices para desagregar las Competencias</strong
                    >
                    en resultados de aprendizajes por Ciclos Formativos y para
                    la creación o ajustes de asignaturas.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="carousel-2-container">
              <div class="carousel-2-sub-container-left">
                <strong>Funcionalidades</strong> (integradas y automatizadas)
              </div>
              <div class="carousel-2-sub-container-right">
                <div
                  class="carousel-2-card carousel-2-background-3 carousel-2-space"
                >
                  <div class="align-self-left mt-3 ml-4">
                    <img
                      src="../../assets/new-page/Carousel-funcionalidades-icono-03.svg"
                      alt="funcionalidades-icono-01"
                    />
                  </div>
                  <div class="ml-4 mr-4 mt-3">
                    Diseña y crea en línea el Plan de Estudio, la Malla
                    Curricular, los Programas de Asignatura y su Ruta de
                    Aprendizaje o Planificación (syllabus).
                  </div>
                </div>
                <div class="carousel-2-card carousel-2-background-4">
                  <div class="align-self-left mt-3 ml-4">
                    <img
                      src="../../assets/new-page/Carousel-funcionalidades-icono-04.svg"
                      alt="funcionalidades-icono-01"
                    />
                  </div>
                  <div class="ml-4 mr-4 mt-3">
                    Diseña y crea el Sistema de Evaluación, concatenado en forma
                    automática con los Indicadores de Logro y Resultados de
                    aprendizaje, en sus instrumentos escritos y de observación,
                    que incluye la aplicación y entrega de resultados.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="carousel-2-container">
              <div class="carousel-2-sub-container-left">
                <strong>Funcionalidades</strong> (integradas y automatizadas)
              </div>
              <div class="carousel-2-sub-container-right">
                <div
                  class="carousel-2-card carousel-2-background-5 carousel-2-space"
                >
                  <div class="align-self-left mt-3 ml-4">
                    <img
                      src="../../assets/new-page/Carousel-funcionalidades-icono-05.svg"
                      alt="funcionalidades-icono-01"
                    />
                  </div>
                  <div class="ml-4 mr-4 mt-3">
                    Informe y reportes automatizados de notas, porcentaje de
                    logros de criterios de evaluación/indicadores de logros,
                    resultados de aprendizaje, y competencias del perfil de
                    egreso en dashboard de docentes, directivos y estudiantes de
                    forma diferenciada.
                  </div>
                </div>
                <div class="carousel-2-card carousel-2-background-6">
                  <div class="align-self-left mt-3 ml-4">
                    <img
                      src="../../assets/new-page/Carousel-funcionalidades-icono-06.svg"
                      alt="funcionalidades-icono-01"
                    />
                  </div>
                  <div class="ml-4 mr-4 mt-3">
                    Genera Actas digitales en línea para los comités de carrera,
                    de currículo, de validación del perfil de egreso u otros;
                    para tener constancia histórica de análisis, estudios y
                    acuerdos, permitiendo adjuntar diversos archivos en cada
                    Acta.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-carousel>
    <div class="home-banner-img"></div>
    <div class="home-carousel-3-background">
      <!-- controls -->
      <b-carousel
        id="carousel-3"
        v-model="slide3"
        :interval="7000"
        indicators
        controls
        @sliding-start="onSlideStart3"
        @sliding-end="onSlideEnd3"
      >
        <div id="carousel-3" class="carousel slide" data-ride="carousel-3">
          <div class="carousel-inner">
            <div class="carousel-item carousel-3-container active">
              <div style="position: absolute; top: 5%" class="carousel-caption">
                <h3 style="position: absolute; left: 0%" class="d-md-block">
                  Beneficios
                </h3>
                <div class="carousel-3-card-container mt-5">
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-01.svg"
                        alt="Beneficios-icono-01"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Digitaliza el proceso de creación de los productos
                        curriculares
                      </span>
                      con su activación en las diferentes matrices o mapas
                      formativos.
                    </div>
                  </div>
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-02.svg"
                        alt="Beneficios-icono-02"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Contexto digital intuitivo para el diseño colaborativo
                      </span>
                      de los planes de estudios y programas de carreras de
                      pregrado y postgrado.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item carousel-3-container active">
              <div style="position: absolute; top: 5%" class="carousel-caption">
                <h3 style="position: absolute; left: 0%" class="d-md-block">
                  Beneficios
                </h3>
                <div class="carousel-3-card-container mt-5">
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/carousel-1-logo-1.svg"
                        alt="soluciones-img-1"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Entrega retroalimentación directa a los estudiantes de
                        sus logros,
                      </span>
                      evidenciando el proceso centrado en el estudiante.
                    </div>
                  </div>
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-04.svg"
                        alt="Beneficios-icono-04"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Registro histórico de documentación curricular
                      </span>
                      y resultados de aprendizaje de los estudiantes para la
                      fundamentación de los procesos de innovación curricular,
                      autoevaluación y acreditación institucional.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item carousel-3-container">
              <div style="position: absolute; top: 5%" class="carousel-caption">
                <h3 style="position: absolute; left: 0%" class="d-md-block">
                  Beneficios
                </h3>
                <div class="carousel-3-card-container mt-5">
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-05.svg"
                        alt="Beneficios-icono-05"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Apoyo metodológico para la generación de las
                        competencias,
                      </span>
                      resultados de aprendizaje e indicadores de logro.
                    </div>
                  </div>
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-06.svg"
                        alt="Beneficios-icono-06"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Reportes automatizados sobre frecuencia
                      </span>
                      y pertinencia cognitiva de las preguntas y dimensiones
                      comprendidas en las evaluaciones y sus instrumentos por
                      asignaturas.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item carousel-3-container">
              <div style="position: absolute; top: 5%" class="carousel-caption">
                <h3 style="position: absolute; left: 0%" class="d-md-block">
                  Beneficios
                </h3>
                <div class="carousel-3-card-container mt-5">
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-07.svg"
                        alt="Beneficios-icono-07"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        El Assessment genera retroalimentación
                      </span>
                      efectiva de logros y seguimiento de resultados de
                      aprendizaje a nivel individual, por asignaturas, sección,
                      carrera y global. Proceso centrado en el estudiante.
                    </div>
                  </div>
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-08.svg"
                        alt="Beneficios-icono-08"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Seguimiento al logro de las competencias del perfil de
                        egreso,
                      </span>
                      desde el inicio de estudios para darle mayor
                      retroalimentación a los estudiantes.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item carousel-3-container">
              <div style="position: absolute; top: 5%" class="carousel-caption">
                <h3 style="position: absolute; left: 0%" class="d-md-block">
                  Beneficios
                </h3>
                <div class="carousel-3-card-container mt-5">
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-09.svg"
                        alt="Beneficios-icono-09"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Asunción de la transformación digital,
                      </span>
                      sin que implique el aumento sistemático de los costos,
                      teniendo acceso permanente a las innovaciones que conciben
                      KL Innovation y KL Assessment.
                    </div>
                  </div>
                  <div class="carousel-3-card">
                    <div class="align-self-left mt-3 ml-4">
                      <img
                        src="../../assets/new-page/Carousel-Beneficios-icono-10.svg"
                        alt="Beneficios-icono-10"
                      />
                    </div>
                    <div class="ml-4 mr-4 mt-3">
                      <span style="font-weight: 700; font-size: 16px">
                        Entrega de información y evidencias integrada para
                        Procesos de Autoevaluación y Acreditación,
                      </span>
                      automatizando la entrega de los currículo y resultados de
                      logros.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel>
    </div>
    <div
      class="mt-5"
      style="display: flex; flex-direction: column; flex-wrap: nowrap"
    >
      <div
        style="
          font-family: 'Source Sans 3';
          font-style: normal;
          font-weight: 600;
          font-size: 48px;
          line-height: 180%;
          /* or 60px */

          text-align: center;
          letter-spacing: -0.022em;

          color: #006ec8;
        "
      >
        Asesorías
      </div>
      <div class="brochure-img home-img-grafic-container mb-1">
        <div class="home-img-grafic"></div>
      </div>
      <div
        class="mb-5"
        style="
          font-family: 'Source Sans 3';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          color: #006ec8;
          background: linear-gradient(90deg, #006ec8 24.78%, #01adb7 74.78%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        "
      >
        Todo lo anterior en base a los propios modelos educativos de las IES
      </div>
    </div>
    <ContactForm :id="'contact-us'"></ContactForm>
    <div style="width: 100%; height: 300px">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.709467129193!2d-70.5702077!3d-33.40474319999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662ced93c8100f7%3A0x1af432465bddb03d!2sCerro%20El%20Plomo%205931%2C%207561160%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1714157056208!5m2!1ses!2scl"
        width="100%"
        height="100%"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
    <ContactBanner></ContactBanner>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    Brochure: () => import("@/components/new-page/Brochure"),
    ContactForm: () => import("@/components/new-page/ContactForm"),
    ContactBanner: () => import("@/components/new-page/ContactBanner"),
  },
  props: {},
  data() {
    return {
      slide1: 0,
      slide2: 0,
      slide3: 0,
      sliding1: null,
      sliding2: null,
      sliding3: null,
    };
  },
  computed: {},
  methods: {
    onSlideStart1() {
      this.sliding1 = true;
    },
    onSlideStart2() {
      this.sliding2 = true;
    },
    onSlideStart3() {
      this.sliding3 = true;
    },
    onSlideEnd1() {
      this.sliding1 = false;
    },
    onSlideEnd2() {
      this.sliding2 = false;
    },
    onSlideEnd3() {
      this.sliding3 = false;
    },
    scrollToDiv(div_id) {
      window.scrollTo({ top: 0 });
      const div = document.getElementById(div_id);
      if (div) {
        const offset = 100; // Número de píxeles para desplazar hacia arriba
        const divPosition = div.getBoundingClientRect().top;
        const offsetPosition = divPosition - offset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.carousel1-img {
  border-radius: 22px;
}
.header-container {
  display: flex;
  flex-direction: row;
}
.header-image-back-1 {
  width: 25%;
  height: 499px;
  background: url(../../assets/new-page/2_banner_principal_home.svg) no-repeat;
  background-position: center;
  background-size: cover;
}
.header-image-back-2 {
  width: 75%;
  height: 499px;
  background: url(../../assets/new-page/1_banner_principal_home.png) no-repeat;
  background-position: left center;
  background-size: cover;
  border-bottom-right-radius: 55px;
}
.header-text {
  position: absolute;
  top: 1%;
  left: 10%;
  width: 50%;
}
.button-container {
  width: 70%;
  text-align: center;
}
.page-background {
  background: #f5f5f5;
}
.header-banner-container {
  width: 100%;
  height: 499px;
  background: url(../../assets/new-page/4_banner_principal_home.svg) no-repeat;
  display: none;
  background-position: center;
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 60px;
  background-size: cover;
}
.brochure-img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 600px;
}
.brochure-img-center {
  width: auto;
  height: 100%;
  background: url(../../assets/new-page/3-kl-innovation-kl assesment-Grafica.svg)
    no-repeat;
  background-position: center;
  background-size: contain;
}
/* .header-banner-img-right {
  width: auto;
  height: 100%;
  background: url(../../assets/new-page/3_banner_img_principal_home.svg)
    no-repeat;
  background-position: left;
  border-bottom-right-radius: 40px;
  background-size: cover;
} */
/* .header-banner-card {
  width: 50%;
} */
.header-banner-right {
  width: 50%;
  display: block;
}
.header-banner-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.font-header {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 150%;
  text-align: left;
  letter-spacing: -0.022em;
  text-transform: uppercase;
  color: #f5f5f5;
}
.font-description {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: left;
  letter-spacing: -0.022em;
  width: 70%;
  color: #f5f5f5;
}
.user-button:hover {
  color: white;
  opacity: 0.9;
}
.user-button {
  background-color: #212121;
  width: 14em;
  height: 4em;
  color: white;
  border-radius: 55px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
#carousel-1:hover {
  filter: brightness(85%);
}
#carousel-1 {
  cursor: pointer;
}
.carousel-1-title {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 30px;
}
.carousel-1-description {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}
#carousel-1 >>> .carousel-control-prev {
  left: 20% !important;
  z-index: 10;
}
#carousel-1 >>> .carousel-control-next {
  right: 20% !important;
  z-index: 10;
}
#carousel-2:hover {
  filter: brightness(95%);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}
#carousel-2 {
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}
#carousel-2 >>> .carousel-control-prev-icon {
  background-image: url(../../assets/new-page/arrow_carousel_2_left.svg);
  width: 38px !important;
  height: 38px !important;
}
#carousel-2 >>> .carousel-control-next-icon {
  background-image: url(../../assets/new-page/arrow_carousel_2_right.svg);
  width: 38px !important;
  height: 38px !important;
}
#carousel-2 >>> .carousel-control-prev {
  left: 5% !important;
  top: 50% !important;
}
#carousel-2 >>> .carousel-control-next {
  right: 70% !important;
  top: 50% !important;
}
#carousel-2 >>> .carousel-indicators li {
  background-color: #01adb7;
}
.carousel-2-style {
  border-radius: 22px;
  width: 1082px;
  margin-right: auto;
  margin-left: auto;
}
.carousel-2-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 400px;
}
.carousel-2-sub-container-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 100%;
  justify-content: center;
  color: #0172c7;
}
.carousel-2-sub-container-right {
  width: 60%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
}
.carousel-2-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 2em;
  width: 264px;
  height: 277px;
  border-radius: 20px;
  color: #ffffff;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: left;

  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.carousel-2-space {
  margin-right: 1rem;
}
.carousel-2-style {
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
}
.carousel-2-background-1 {
  background: url(../../assets/new-page/Carousel-funcionalidades-fondo-01.svg)
    no-repeat center;
}
.carousel-2-background-2 {
  background: url(../../assets/new-page/Carousel-funcionalidades-fondo-02.svg)
    no-repeat center;
}
.carousel-2-background-3 {
  background: url(../../assets/new-page/Carousel-funcionalidades-fondo-03.svg)
    no-repeat center;
}
.carousel-2-background-4 {
  background: url(../../assets/new-page/Carousel-funcionalidades-fondo-04.svg)
    no-repeat center;
}
.carousel-2-background-5 {
  background: url(../../assets/new-page/Carousel-funcionalidades-fondo-05.svg)
    no-repeat center;
}
.carousel-2-background-6 {
  background: url(../../assets/new-page/Carousel-funcionalidades-fondo-06.svg)
    no-repeat center;
}
.home-banner-img {
  width: 100%;
  height: 386px;
  background: url(../../assets/new-page/4_banner_img_home.jpg) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
#carousel-3:hover {
  filter: brightness(95%);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.3);
}
#carousel-3 {
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}
.home-carousel-3-background {
  width: 100%;
  height: 500px;
  background: url(../../assets/new-page/rectangle-img-carousel-3.svg) no-repeat
    center center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.carousel-3-container {
  height: 500px;
}
.home-img-grafic-container {
  height: 480px !important;
}
.carousel-3-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.carousel-3-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 2em;
  width: 300px;
  height: 275px;
  border-radius: 20px;
  background: #ffffff;
  color: #006ec8;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 0;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
}
.home-img-grafic {
  width: auto;
  height: 100%;
  background: url(../../assets/new-page/home_Asesorias_grafico_img.svg)
    no-repeat;
  background-position: center;
  background-size: contain;
}
@media (max-width: 1500px) {
  .font-header {
    font-size: 44px;
  }
  .font-description {
    font-size: 22px;
  }
  #carousel-1 >>> .carousel-control-prev {
    left: 8% !important;
  }
  #carousel-1 >>> .carousel-control-next {
    right: 8% !important;
  }
  .carousel-1-title {
    width: 60%;
  }
  .carousel-1-description {
    width: 60%;
  }
  .home-carousel-3-background {
    height: 470px;
  }
  .carousel-3-container {
    height: 470px;
  }
}
@media (max-width: 1000px) {
  .header-container {
    display: none;
  }
  .header-banner-container {
    display: flex;
    background: url(../../assets/new-page/2_banner_principal_home.svg) no-repeat;
  }
  #carousel-2 >>> .carousel-control-prev {
    left: 25% !important;
    top: -50% !important;
  }
  #carousel-2 >>> .carousel-control-next {
    right: 25% !important;
    top: -50% !important;
  }
  #carousel-1 >>> .carousel-control-prev {
    left: 0% !important;
  }
  #carousel-1 >>> .carousel-control-next {
    right: 0% !important;
  }
  .header-banner-container {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    height: auto;
  }
  .header-banner-right {
    display: none;
  }
  .header-banner-card {
    margin-top: 1em;
    width: 100%;
  }
  .brochure-img {
    width: 100%;
    height: 300px;
  }
  .home-img-grafic-container {
    height: 300px !important;
  }
  .carousel-1-title {
    width: 80%;
  }
  .carousel-1-description {
    width: 80%;
  }
  .carousel-2-style {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }
  .carousel-2-container {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
  }
  .carousel-2-sub-container-left {
    width: 100%;
    margin-bottom: 4rem;
  }
  .carousel-2-sub-container-right {
    width: 100%;
    flex-wrap: nowrap;
  }
  .carousel-3-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .carousel-3-card {
    margin-right: 10px;
  }
  .carousel-2-card {
    margin-bottom: 3em;
  }
}
@media (max-width: 770px) {
  .home-carousel-3-background {
    height: 730px;
  }
  .carousel-3-card {
    display: block;
    width: 100%;
    width: 90%;
  }
  .carousel-3-container {
    height: 730px;
  }
  .carousel-3-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
@media (max-width: 700px) {
  .home-carousel-3-background {
    height: 700px;
  }
  .carousel-3-container {
    height: 700px;
  }
  .brochure-img {
    width: 100%;
    height: 200px;
  }
  .home-img-grafic-container {
    height: 200px !important;
  }
  .carousel-1-title {
    width: 100%;
  }
  .carousel-1-description {
    width: 100%;
  }
  .carousel-caption {
    top: 0 !important;
  }
  .carousel-2-sub-container-right {
    width: 100%;
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    .font-header {
      text-align: center;
    }
    #carousel-2 >>> .carousel-control-prev {
      top: -70% !important;
    }
    #carousel-2 >>> .carousel-control-next {
      top: -70% !important;
    }
    .carousel-2-space {
      margin-right: 0rem;
    }
  }
  @media (max-width: 485px) {
    .header-banner-container {
      height: auto;
      background-size: cover;
    }
    #carousel-2 >>> .carousel-control-prev {
      top: -65% !important;
    }
    #carousel-2 >>> .carousel-control-next {
      top: -65% !important;
    }
    .carousel-2-card {
      margin-bottom: 3em;
    }
    .carousel-2-space {
      margin-right: 0rem;
    }
  }
}
</style>